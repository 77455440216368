
import { Vue, Component } from "vue-property-decorator";
import DeploymentTable from "@/components/deployment/DeploymentTable.vue";
import { DeploymentModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import EbarTable from "@/components/ebar/EbarTable.vue";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    DeploymentTable,
    EbarTable,
  },
})
export default class Home extends Vue {
  private tableRefreshKey = false;
  private itemsPerPage = 4;
  private selectedDeploymentId = "";
  private selectedSessionId = "";
  private loading = false;
  private items: Array<DeploymentModel> = [];

  private async created() {
    await this.getActiveAndRecent();
  }

  private get adminOnsiteUserPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private get portfolioManagerPermissions() {
    return userModule.userRole == userRoleConstant.porfolioManager;
  }

  private get customerAdminPermissions() {
    return userModule.userRole == userRoleConstant.customerAdmin;
  }

  private get venueManagerPermissions() {
    return userModule.userRole == userRoleConstant.venueManager;
  }

  private async getActiveAndRecent() {
    try {
      this.loading = true;
      if (this.adminOnsiteUserPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentActiveAndRecentGet();
        this.items = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(
            userModule.organisationId
          );
        this.items = response.data;
      }
      if (this.venueManagerPermissions) {
        const response =
          await api.DeploymentService.apiDeploymentByvenueActiveAndRecentVenueIdGet(
            userModule.venueId
          );
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to retrieve all active and recent deployments"
      );
    } finally {
      this.loading = false;
    }
  }

  private showProfile(item: any) {
    this.$router.push({
      name: "DeploymentProfile",
      params: { deploymentId: item.deploymentId },
    });
  }
}


import { Vue, Component } from "vue-property-decorator";
import api from "@/api";
import { KioskModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class EbarTable extends Vue {
  private loading = false;
  private selectedItem = "";
  private items: Array<KioskModel> = [];
  private headers: Array<DataTableHeader<KioskModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<KioskModel>("kioskCode") },
    { text: "TID", value: nameof<KioskModel>("terminalId") },
    { text: "Deployed", value: nameof<KioskModel>("deployed") },
    { text: "State", value: nameof<KioskModel>("errorState") },
  ];
  private async created() {
    await this.getKiosks();
  }

  private async getKiosks() {
    try {
      this.loading = true;
      const response = await api.KioskService.apiKioskGet();
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Kiosks");
    } finally {
      this.loading = false;
    }
  }

  private showProfile(item: any, row: any) {
    row.select(true);
    this.$router.push({
      name: "EbarProfile",
      params: { kioskId: item.kioskId },
    });
  }
}
